import {
  ConsoleInstrumentation,
  ConsoleTransport,
  ErrorsInstrumentation,
  FetchTransport,
  InternalLoggerLevel,
  LogLevel,
  SessionInstrumentation,
  WebVitalsInstrumentation,
} from '@grafana/faro-web-sdk';

import { getEnvironmentFromUrl } from '@cccom/config/env';
import { version } from '@cccom/package';

export const getFaroConfig = (url: string) => ({
  instrumentations: [
    new ErrorsInstrumentation(),
    new WebVitalsInstrumentation(),
    new ConsoleInstrumentation({
      disabledLevels: [LogLevel.TRACE, LogLevel.LOG, LogLevel.DEBUG],
    }),
    new SessionInstrumentation(),
  ],
  transports: [new FetchTransport({ url }), new ConsoleTransport()],
  app: {
    name: 'frontend',
    version,
    environment: getEnvironmentFromUrl(),
  },
  internalLoggerLevel: InternalLoggerLevel.ERROR,
});
