import { useCallback, useContext } from 'react';

import { Permissions } from '@cccom/auth/permissions-type';

import mapPermissions from './permission-mapper';
import { PermissionsContext } from './PermissionsContext';
import { CCPermissions } from './types';

export function accessNestedObject(
  keys: string[],
  obj: CCPermissions | Permissions[]
): Permissions[] {
  const key = keys.shift();
  if (!key && Array.isArray(obj)) return obj;

  if (key && !Array.isArray(obj)) {
    return accessNestedObject(keys, obj[key]);
  }

  return [];
}

/**
 * hook for managing PermissionsContext
 */
export function usePermissions() {
  const { permissionsContext, setPermissionsContext } =
    useContext(PermissionsContext);
  const { roles, permissions, key } = permissionsContext;
  const permissionsArr = accessNestedObject(key.split('.'), permissions);

  const hasPermission = useCallback(
    ({
      scopes,
      permissions: _permissions = permissionsArr,
    }: {
      scopes: Permissions[];
      permissions?: Permissions[];
    }) => {
      if (scopes.length === 0) return true;

      const combinedPermissions = [..._permissions, ...roles];
      return combinedPermissions.some((permission) =>
        scopes.includes(permission)
      );
    },
    [permissionsArr, roles]
  );

  const setContext = useCallback(
    (_permissions: string[], _key: string) => {
      const mappedPerms = mapPermissions(_permissions);

      setPermissionsContext({
        roles,
        permissions: { ...permissions, ...mappedPerms },
        key: _key,
      });
    },
    [roles, permissions, setPermissionsContext]
  );

  return {
    hasPermission,
    setPermissionsContext: setContext,
  };
}
