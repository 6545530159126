import { useContext } from 'react';

import CCAxiosContext from './context';

const useHttpClient = () => {
  const client = useContext(CCAxiosContext);
  return client;
};

export default useHttpClient;
