function getPastDate(numOfDays: number): number {
  if (numOfDays <= 0)
    throw new Error(
      `${numOfDays} should be non-zero non-negative number of days`
    );
  const date = new Date();
  return date.setDate(date.getDate() - numOfDays);
}

export default getPastDate;
