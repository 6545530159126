import { theme as proTheme } from '@chakra-ui/pro-theme';
import { defineStyleConfig, extendTheme } from '@chakra-ui/react';

const baseTheme = extendTheme(proTheme);

export const Checkbox = defineStyleConfig({
  ...baseTheme['components'].Checkbox,
  baseStyle: {
    ...baseTheme['components'].Checkbox.baseStyle,
    control: {
      ...baseTheme['components'].Checkbox.baseStyle.control,
      _disabled: {
        bg: baseTheme['colors'].gray['200'],
        borderColor: baseTheme['colors'].gray['200'],
      },
    },
  },
});
