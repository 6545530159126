import { useState } from 'react';

export type UseFilterStateProps<T> = {
  defaultValue: T | undefined;
  onSubmit?: (value: T) => void;
  onReset?: () => void;
};

export function useFilterState<T>(props: UseFilterStateProps<T>) {
  const { defaultValue, onSubmit, onReset } = props;
  const [state, setState] = useState(defaultValue);
  return {
    canCancel: state !== undefined,
    value: state,
    onChange: setState,
    onReset() {
      setState(defaultValue);
      onReset?.();
    },
    onSubmit() {
      onSubmit?.(state as T);
    },
  };
}
