import { Select } from '@chakra-ui/react';
import { ForwardedRef, forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { i18n } from '@cccom/shared/i18n';
import getPastDate from './date.util';

type DateFilterProps = {
  onChange: (value: string) => void;
  onClear: () => void;
  placeholder?: string;
};

const DateFilter = forwardRef(function DateFilter(
  {
    onChange,
    onClear,
    placeholder = i18n.t('common.labels.date'),
  }: DateFilterProps,
  ref: ForwardedRef<{ clear: () => void }>
) {
  const selectRef = useRef<HTMLSelectElement | null>();
  const [t] = useTranslation();

  useImperativeHandle(ref, () => {
    return {
      clear: () => {
        if (selectRef.current) selectRef.current.value = '';
        onClear();
      },
    };
  }, [onClear]);

  return (
    <Select
      ref={(element) => {
        selectRef.current = element;
      }}
      aria-label={placeholder}
      w="fit-content"
      rounded="lg"
      fontSize="sm"
      iconColor="gray.500"
      cursor="pointer"
      focusBorderColor="brand.500"
      name="date-picker"
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value)}
    >
      <option value={getPastDate(1)}>{t('filters.date.yesterday')}</option>
      <option value={getPastDate(7)}>
        {t('filters.date.last_num_days', { replace: [7] })}
      </option>
      <option value={getPastDate(14)}>
        {t('filters.date.last_num_days', { replace: [14] })}
      </option>
      <option value={getPastDate(28)}>{t('filters.date.last_month')}</option>
      <option value={getPastDate(168)}>
        {t('filters.date.last_num_months', { replace: [6] })}
      </option>
      <option value={getPastDate(365)}>{t('filters.date.last_year')}</option>
    </Select>
  );
});

export default DateFilter;
