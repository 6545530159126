import { useContext } from 'react';

import EnvironmentContext from './context';

function useEnvironment() {
  const env = useContext(EnvironmentContext);

  if (!env) {
    throw new Error(
      'useEnvironment hook must be used inside EnvironmentProvider context'
    );
  }

  return env;
}

export default useEnvironment;
