import { useEnvironment } from '@cccom/config/env';
import { useTranslation } from 'react-i18next';
import './AuthLoading.scss';
import { APP_IDS } from '@cccom/config/app-type';

function AuthLoading() {
  const { APP } = useEnvironment();
  const [t] = useTranslation();

  if (!APP)
    return (
      <div className="auth-container">
        <h1>{t('common.labels.authenticating')}</h1>
      </div>
    );

  return (
    <div className="auth-loading-container">
      <div className="app-splash" data-testid="spinner">
        <img alt="application logo" src={APP.logo} />
        {APP.id === APP_IDS.HOME ? null : <h1>{APP.appFamily}</h1>}
      </div>
    </div>
  );
}

export default AuthLoading;
