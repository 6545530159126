import { theme as proTheme } from '@chakra-ui/pro-theme';
import {
  defineStyle,
  defineStyleConfig,
  extendTheme,
  theme,
} from '@chakra-ui/react';

const baseTheme = extendTheme(proTheme);

const solidRounded = defineStyle((props) => ({
  ...theme.components.Badge.variants?.solid(props),
  borderRadius: '8',
  textTransform: 'none',
  fontWeight: 'normal',
  fontSize: 'xs',
}));

const Badge = defineStyleConfig({
  ...baseTheme['components'].Badge,
  variants: {
    ...baseTheme['components'].Button.variants,
    solidRounded,
  },
});

export default Badge;
