import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useEffect } from 'react';

import { HAS_REDIRECTED } from '@cccom/auth/keycloak';
import { usePermissions } from '@cccom/auth/permissions';
import { APPS } from '@cccom/config/app-config';
import { App, AppIds } from '@cccom/config/app-type';
import { useEnvironment } from '@cccom/config/env';
import { useHttpClient } from '@cccom/shared/data-access';

import {
  CCApp,
  ConfigSettings,
  HelpCenter,
  TimeZone,
  UpdateAppAccess,
} from './types';

const prefix = '/settings';

export function useGetConfig() {
  const client = useHttpClient();
  const getConfigurations = async () =>
    (await client.get<ConfigSettings>(`${prefix}/configurations`)).data;
  const query = useQuery<ConfigSettings, AxiosError>({
    queryKey: ['getConfigurations'],
    queryFn: getConfigurations,
    staleTime: Infinity,
  });
  return query;
}

export function useTimeZone() {
  const client = useHttpClient();
  const getTimezone = async () =>
    (await client.get<TimeZone>(`${prefix}/timezones/global`)).data;
  const query = useQuery<TimeZone, AxiosError>({
    queryKey: ['getTimeZone'],
    queryFn: getTimezone,
    staleTime: Infinity,
  });
  return query;
}

export function useHelpCenter() {
  const env = useEnvironment();
  const client = useHttpClient();
  // TODO: remove this when we have discussed possible changes with the backend team
  const helpCenter = env.APP?.helpCenter?.toLowerCase();
  const getHelpCenter = async () =>
    (await client.get<HelpCenter>(`${prefix}/help-centers/${helpCenter}`)).data;
  const query = useQuery<HelpCenter, AxiosError>({
    queryKey: ['getHelpCenter'],
    queryFn: getHelpCenter,
    staleTime: Infinity,
  });
  return query;
}

export const useAppsList = () => {
  const client = useHttpClient();
  const { hasPermission } = usePermissions();

  const getAppList = async () => {
    const response = await client.get<CCApp[]>(`${prefix}/apps`);
    return response.data;
  };

  const query = useQuery<CCApp[], AxiosError, App[]>({
    queryKey: ['getAppList'],
    queryFn: getAppList,
    staleTime: Infinity,
    select: (ccApp) => {
      const apps = ccApp.reduce<App[]>((prev, { name, lastAccessed }) => {
        const app = APPS[name.toUpperCase() as AppIds];
        if (app && hasPermission({ scopes: app.scopes ?? [] })) {
          return [...prev, { ...app, lastAccessed }];
        }
        return prev;
      }, []);

      return apps;
    },
  });
  return query;
};

export const useApp = (id: number) => {
  const client = useHttpClient();

  const getApp = async () => {
    const response = await client.get<CCApp>(`${prefix}/apps/${id}`);
    return response.data;
  };

  const query = useQuery<CCApp, AxiosError>({
    queryKey: ['getApp'],
    queryFn: getApp,
  });

  return query;
};

export const useUpdateAppAccess = ({ name }: UpdateAppAccess) => {
  const client = useHttpClient();

  const updateAppAccess = async (_data: UpdateAppAccess) => {
    localStorage.setItem(HAS_REDIRECTED, 'true');
    const response = await client.post(`${prefix}/app-access`, _data);
    return response.data;
  };

  const updateAccess = useMutation({ mutationFn: updateAppAccess });
  useEffect(() => updateAccess.mutate({ name }), []);
};

export function useWizardSettings() {
  const client = useHttpClient();

  const getWizardPreferences = async () => {
    // TODO: remove the '_' at the end of the url once the BE is updated
    const response = await client.get(`${prefix}/wizard/_`);
    return response.data;
  };

  const query = useQuery<{ skip: boolean }, AxiosError>({
    queryKey: [`settings-wizard`],
    queryFn: () => getWizardPreferences(),
    staleTime: Infinity,
  });

  return query;
}
