import baseTheme from '../../base-theme';

export const SysInventoryStatusColors = {
  DECOMMISSIONED: {
    ...baseTheme['colors'].pink,
    100: baseTheme['colors'].pink['100'],
  },
  ON_HOLD: {
    ...baseTheme['colors'].yellow,
    100: baseTheme['colors'].yellow['100'],
  },
  IN_USE: {
    ...baseTheme['colors'].green,
    100: baseTheme['colors'].green['100'],
  },
  IN_DEVELOPMENT: {
    ...baseTheme['colors'].blue,
    100: baseTheme['colors'].blue['100'],
  },
};
