import { useState, useEffect } from 'react';

import { getEnvironmentFromUrl, useEnvironment } from '@cccom/config/env';

const LOGO_MAP: Record<string, string> = {
  dev: 'odysseyvc_logo.svg',
  qa: 'odysseyvc_logo.svg',
  demo: 'odysseyvc_logo.svg',
  mnk: 'mnk_logo.svg',
  amgen: 'amgen_logo.svg',
};

export const useCompanyLogoSrc = (): { companyLogoSrc: string } => {
  const env = useEnvironment();
  const [companyLogoSrc, setCompanyLogoSrc] = useState<string>('');

  const baseUrl = `${env.STATIC_ASSETS_URL}/images/logos`;

  useEffect(() => {
    const environment = getEnvironmentFromUrl();
    setCompanyLogoSrc(
      `${baseUrl}/${LOGO_MAP[environment] || 'odysseyvc_logo.svg'}`
    );
  }, [baseUrl]);

  return { companyLogoSrc };
};
