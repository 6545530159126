import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import enGB from '../locales/en-GB/translation.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-GB',
    debug: false,

    interpolation: {
      escapeValue: false,
    },

    resources: {
      'en-GB': { translation: enGB },
    },
  });

export default i18n;
