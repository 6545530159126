import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Icon,
  Image,
  Link,
  Tag,
  Text,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { TbArrowRight } from 'react-icons/tb';

import { APPS } from '@cccom/config/app-config';
import { App } from '@cccom/config/app-type';
import { useEnvironment } from '@cccom/config/env';

function BgCircle({
  multiplier,
  color,
}: {
  multiplier: number;
  color: string;
}) {
  const circleSize = '430px';
  const size = `calc(${circleSize} - ${multiplier}px)`;

  return (
    <Box
      position="absolute"
      rounded="full"
      opacity="0.3"
      right="-120%"
      bottom="-120%"
      h={size}
      w={size}
      background={color}
    />
  );
}

export type AppCardProps = {
  app: App;
};

export function AppCard({ app }: AppCardProps) {
  const [t] = useTranslation();
  const env = useEnvironment();

  const { name, description, status, logo, theme } = app;
  const color = theme['colors'].brand;
  const brandColor = color['300'];
  const href = APPS.getUrl(env, app);
  const isFuture = status === 'FUTURE';

  const cardPadding = 7;
  const cardWidth = '16rem';

  return (
    <Link
      position="relative"
      textAlign="left"
      href={href}
      as={isFuture ? 'button' : 'a'}
      _hover={{ textDecoration: 'none' }}
    >
      {isFuture && (
        <Tag
          h="4"
          top="2"
          right="2"
          zIndex="1"
          size="sm"
          pos="absolute"
          variant="solid"
          colorScheme="teal"
        >
          {t('home.landing.coming_soon')}
        </Tag>
      )}

      <Card
        role="group"
        position="relative"
        overflow="hidden"
        gap="4"
        transition="all 0.3s"
        minH="11.5rem"
        p={cardPadding}
        w={cardWidth}
        opacity={isFuture ? '0.8' : '1'}
        filter={isFuture ? 'grayscale(65%)' : ''}
        _hover={{ boxShadow: `4px 4px 12px -4px rgba(0, 0, 0, 0.3)` }}
        _active={{ boxShadow: 'inset var(--card-shadow)' }}
        data-cy={name}
      >
        <CardHeader display="flex" alignItems="center" p="0" gap="4" zIndex="1">
          <Image
            src={logo}
            alt="logo"
            height="40px"
            transition="all 0.3s"
            _groupHover={{ height: '30px' }}
          />
          <Text>{name}</Text>
        </CardHeader>

        {description && (
          <CardBody p="0" color="gray.500" fontSize="xs" zIndex="1">
            <Text>{description}</Text>
          </CardBody>
        )}

        {/* On hover animation elements */}
        <Box
          h="full"
          w="full"
          top="0"
          right="-25px"
          position="absolute"
          transform="skew(-20deg)"
          transition="all 0.4s"
          opacity="0.8"
          background={color['50']}
          left={`calc(-${cardWidth} - 2rem)`}
          _groupHover={{ left: '-10', width: '150%' }}
        />

        <Box
          position="absolute"
          h="full"
          w="full"
          top="0"
          transition="all 0.4s"
          left={cardWidth}
          _groupHover={{ left: '0' }}
        >
          {Array.from({ length: 2 }).map((_, i) => {
            const index = i + 1;
            const multiplier = index * 30;

            return (
              <BgCircle
                key={index}
                multiplier={multiplier}
                color={brandColor}
              />
            );
          })}
        </Box>

        <Icon
          position="absolute"
          transition="all 0.4s"
          as={TbArrowRight}
          bottom={`-${cardPadding}`}
          right={`-${cardPadding}`}
          _groupHover={{ bottom: '4', right: '4' }}
        />
      </Card>
    </Link>
  );
}
