function InlineSvgNoInternet() {
  const brandLight = 'var(--chakra-colors-brand-25)';
  const brandDark = 'var(--chakra-colors-brand-400)';

  return (
    <svg
      width="325"
      height="324"
      viewBox="0 0 325 324"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="146.5"
        height="40"
        transform="translate(77 30)"
        fill="white"
      />
      <circle cx="154.635" cy="163.635" r="146.635" fill={brandLight} />
      <g filter="url(#filter0_d_6387_53978)">
        <circle cx="22.3496" cy="43.0487" r="14.3496" fill={brandLight} />
      </g>
      <g filter="url(#filter1_d_6387_53978)">
        <circle cx="255.53" cy="14.3496" r="14.3496" fill={brandLight} />
      </g>
      <g filter="url(#filter2_d_6387_53978)">
        <ellipse
          cx="301.269"
          cy="43.4971"
          rx="19.7307"
          ry="19.2822"
          fill={brandLight}
        />
      </g>
      <g filter="url(#filter3_d_6387_53978)">
        <ellipse
          cx="281.539"
          cy="301.789"
          rx="14.3496"
          ry="13.9011"
          fill={brandLight}
        />
      </g>
      <g filter="url(#filter4_d_6387_53978)">
        <ellipse
          cx="31.3178"
          cy="290.13"
          rx="14.3496"
          ry="13.9011"
          fill={brandLight}
        />
      </g>
      <path
        d="M137.07 98.454C119.602 100.448 102.373 105.633 87.8966 113.25C78.0859 118.434 68.2353 125.054 66.7598 127.487C64.6062 130.997 67.2782 136.101 71.6651 136.819C73.4198 137.098 75.2544 136.261 79.2424 133.39C88.9733 126.371 102.493 119.75 114.657 116.081C126.581 112.452 135.913 111.016 149.114 110.737C162.713 110.458 171.287 111.335 182.813 114.247L187.638 115.443L189.513 113.728C191.906 111.495 195.854 108.823 198.606 107.547C199.842 106.988 200.759 106.51 200.679 106.43C200.241 106.071 191.307 103.28 186.801 102.083C181.018 100.608 177.03 99.8099 170.21 98.8129C164.069 97.9355 143.65 97.6962 137.07 98.454Z"
        fill={brandDark}
      />
      <path
        d="M210.291 110.578C199.483 112.093 189.672 120.069 185.764 130.558C182.534 139.172 183.092 146.869 187.519 155.483C190.111 160.588 195.136 165.613 199.962 167.966C204.947 170.439 208.775 171.316 214.319 171.316C231.029 171.316 243.591 159.551 244.867 142.761C245.545 134.107 242.235 125.613 235.655 119.152C230.271 113.888 223.651 110.976 215.914 110.498C214.04 110.378 211.527 110.418 210.291 110.578ZM215.555 122.183C215.754 122.183 216.273 122.542 216.712 122.981C217.469 123.738 217.509 124.297 217.509 132.951V142.163L216.512 143.12C215.356 144.317 213.561 144.396 212.285 143.36C211.328 142.602 211.328 142.522 211.208 133.549C211.088 125.772 211.168 124.376 211.727 123.539C212.444 122.462 213.8 121.744 214.638 122.023C214.957 122.103 215.356 122.183 215.555 122.183ZM216.632 151.455C219.583 152.971 219.583 157.996 216.632 159.511C214.678 160.508 212.803 160.229 211.288 158.714C207.539 154.965 211.926 149.023 216.632 151.455Z"
        fill={brandDark}
      />
      <path
        d="M141.137 131.355C122.952 133.389 106.321 139.531 91.7249 149.621C87.0588 152.811 86.3011 153.689 86.0219 156.241C85.5832 160.03 88.4148 163.26 92.1237 163.26C94.2773 163.26 94.7957 162.981 100.539 159.073C109.153 153.25 121.316 148.105 131.965 145.752C144.328 143.041 160.2 143.12 172.643 145.912C175.036 146.47 177.11 146.909 177.269 146.909C177.389 146.909 177.548 144.436 177.548 141.405C177.588 138.414 177.668 135.503 177.708 134.945C177.827 134.027 177.628 133.908 174.637 133.27C167.299 131.714 162.992 131.276 153.7 131.156C148.555 131.076 142.892 131.156 141.137 131.355Z"
        fill={brandDark}
      />
      <path
        d="M143.73 164.496C132.563 165.773 121.317 169.84 112.104 176.022C106.92 179.452 106.042 180.568 106.042 183.679C106.042 187.468 108.395 189.781 112.184 189.781C114.338 189.781 114.657 189.621 118.445 187.109C125.185 182.562 131.965 179.731 140.3 177.936C145.684 176.78 157.209 176.66 162.753 177.737C170.649 179.212 178.586 182.403 184.847 186.59C189.313 189.581 190.111 189.98 191.786 189.98C194.418 189.98 197.01 187.906 197.968 185.035C198.566 183.161 197.928 180.808 196.332 179.252C192.983 175.862 182.135 170.04 174.877 167.647C165.425 164.576 153.62 163.38 143.73 164.496Z"
        fill={brandDark}
      />
      <path
        d="M146.042 194.726C137.867 197.358 132.324 205.574 133.121 213.988C133.998 223.44 140.738 230.22 150.07 230.938C160.2 231.695 168.296 225.474 170.091 215.464C171.686 206.61 165.983 197.438 157.249 194.726C154.098 193.729 149.153 193.769 146.042 194.726Z"
        fill={brandDark}
      />
      <defs>
        <filter
          id="filter0_d_6387_53978"
          x="4"
          y="28.6991"
          width="36.6992"
          height="36.6991"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.175434 0 0 0 0 0.6557 0 0 0 0 0.895833 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_6387_53978"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6387_53978"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_6387_53978"
          x="237.181"
          y="0"
          width="36.6992"
          height="36.6991"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.175434 0 0 0 0 0.6557 0 0 0 0 0.895833 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_6387_53978"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6387_53978"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_6387_53978"
          x="277.539"
          y="24.2149"
          width="47.4614"
          height="46.5645"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.839216 0 0 0 0 0.92549 0 0 0 0 0.968627 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_6387_53978"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6387_53978"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_6387_53978"
          x="263.189"
          y="287.888"
          width="36.6992"
          height="35.8023"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.175434 0 0 0 0 0.6557 0 0 0 0 0.895833 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_6387_53978"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6387_53978"
            result="shape"
          />
        </filter>
        <filter
          id="filter4_d_6387_53978"
          x="12.9683"
          y="276.229"
          width="36.6992"
          height="35.8023"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.175434 0 0 0 0 0.6557 0 0 0 0 0.895833 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_6387_53978"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6387_53978"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default InlineSvgNoInternet;
