import { Box, Divider, Flex, Text, VStack } from '@chakra-ui/react';
import { useMemo } from 'react';

import { App, AppFamilyNames } from '@cccom/config/app-type';
import { useSettings } from '@cccom/shared/settings';
import { AppCard } from '@cccom/shared/ui';

export function AppList() {
  const { appList } = useSettings();

  const appFamilies = useMemo(() => {
    const family = appList.reduce((prev, app) => {
      const { appFamily } = app;
      const prevFamily = prev.get(appFamily) ?? [];
      prev.set(appFamily, [...prevFamily, app]);
      return prev;
    }, new Map<AppFamilyNames, App[]>());

    return [...family.entries()];
  }, [appList]);

  return (
    <Flex gap="14" direction="column" mt="7">
      {appFamilies.map(([family, apps]) => {
        return (
          <VStack
            as="section"
            gap="7"
            position="relative"
            alignItems="start"
            key={family}
          >
            <Box>
              <Text
                as="h1"
                fontSize="3xl"
                fontWeight="bold"
                color="gray.500"
                pb="4"
              >
                {family}
              </Text>
              <Flex gap="14" pb="14" flexWrap="wrap">
                {apps.map((app) => app && <AppCard key={app.id} app={app} />)}
              </Flex>
            </Box>

            {appFamilies.length > 1 && (
              <Divider
                position="absolute"
                width="calc(100% + var(--chakra-space-14))"
                left="-7"
                bottom="0"
              />
            )}
          </VStack>
        );
      })}
    </Flex>
  );
}
