import { theme as proTheme } from '@chakra-ui/pro-theme';
import {
  defineStyle,
  defineStyleConfig,
  extendTheme,
  theme,
} from '@chakra-ui/react';

const baseTheme = extendTheme(proTheme);

const outline = defineStyle((props) => ({
  ...theme.components.Button.variants?.outline(props),
  color: 'inherit',
  borderColor: 'gray.200',
  _hover: {
    background: 'gray.50',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'gray.200',
  },
  _active: {
    background: 'gray.100',
  },
}));

const outlineWithHover = defineStyle((props) => {
  const { colorScheme: c } = props;
  return {
    ...theme.components.Button.variants?.outline(props),
    color: `gray.600`,
    borderColor: `gray.200`,
    _hover: {
      color: `${c}.600`,
      background: `${c}.50`,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: `${c}.200`,
    },
    _active: {
      color: `${c}.600`,
      background: `${c}.100`,
    },
    _disabled: {
      _hover: {
        color: `gray.600`,
        background: `inherit`,
        borderColor: `gray.200`,
      },
      _active: {
        color: `gray.600`,
        background: `inherit`,
        borderColor: `gray.200`,
      },
    },
  };
});

const iconNoBorder = defineStyle((props) => ({
  ...theme.components.Button.variants?.outline(props),
  color: 'inherit',
  border: 'none',
  _hover: {
    background: 'gray.200',
  },
  _active: {
    background: 'gray.200',
  },
}));

const Button = defineStyleConfig({
  ...baseTheme['components'].Button,
  baseStyle: {
    ...baseTheme['components'].Button.baseStyle,
    fontWeight: 'medium',
  },
  variants: {
    ...baseTheme['components'].Button.variants,
    outline,
    outlineWithHover,
    iconNoBorder,
  },
});

export default Button;
