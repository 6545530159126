import { ReactNode, createContext, useMemo, useState } from 'react';

export type DrawerReturnType = {
  drawerId: string;
  isOpen: boolean;
  selectedDrawer: any;
  onOpen: () => void;
  onClose: () => void;
  setSelectedDrawer: (drawer: any) => void;
  onToggle: (drawer: any) => void;
};

type UIContextState = {
  drawers: Record<string, DrawerReturnType>;
  setDrawers: (drawers: Record<string, DrawerReturnType>) => void;
};

export const UIContext = createContext<UIContextState>({
  drawers: {},
  setDrawers: () => null,
});

export function UIProvider({ children }: { children: ReactNode }) {
  const [drawers, setDrawers] = useState<UIContextState['drawers']>({});

  const state: UIContextState = useMemo(() => {
    return { drawers, setDrawers };
  }, [drawers]);

  return <UIContext.Provider value={state}>{children}</UIContext.Provider>;
}
