import { extendTheme } from '@chakra-ui/react';
import baseTheme from '../../base-theme';
import { QIStatusColors } from './qi-status-colors';

const theme = extendTheme({
  ...baseTheme,
  colors: {
    ...baseTheme['colors'],
    ...QIStatusColors,
    brand: {
      ...baseTheme['colors'].blue,
      topbar: baseTheme['colors'].blue['500'],
      25: baseTheme['colors'].blue['100'],
    },
  },
});

export default theme;
