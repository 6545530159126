import { Avatar, AvatarProps, useToken } from '@chakra-ui/react';

const colorMap: { [key: string]: string } = {
  A: '#DD6B20',
  B: '#2D3748',
  C: '#718096',
  D: '#F56565',
  E: '#9B2C2C',
  F: '#63171B',
  G: '#ED8936',
  H: '#9C4221',
  I: '#652B19',
  J: '#ECC94B',
  K: '#975A16',
  L: '#5F370E',
  M: '#48BB78',
  N: '#276749',
  O: '#1C4532',
  P: '#38B2AC',
  Q: '#285E61',
  R: '#1D4044',
  S: '#4299E1',
  T: '#2C5282',
  U: '#1A365D',
  V: '#9F7AEA',
  W: '#553C9A',
  X: '#322659',
  Y: '#ED64A6',
  Z: '#97266D',
};

export function useGetNameColor() {
  const [brand300] = useToken('colors', ['brand.300']);
  const getColor = (name: string) => {
    const firstLetter = name.charAt(0).toUpperCase();
    return colorMap[firstLetter] ?? brand300;
  };
  return { getColor };
}

export type CCAvatarProps = AvatarProps & { name: string };

export function CCAvatar(props: CCAvatarProps) {
  const { getColor } = useGetNameColor();
  const { name } = props;
  return (
    <Avatar
      size="sm"
      color="white"
      data-testid="avatar"
      bg={getColor(name)}
      {...props}
    />
  );
}
