import { ToastPosition } from '@chakra-ui/react';

type ToastAttributes = {
  variant:
    | string
    | 'subtle'
    | 'left-accent'
    | 'top-accent'
    | 'solid'
    | undefined;
  duration: number | null;
  position: ToastPosition;
  isClosable: boolean;
  id: string;
};

const toastAttributes: ToastAttributes = {
  variant: 'left-accent',
  duration: 3000,
  position: 'bottom-right',
  isClosable: true,
  id: '',
};

export default toastAttributes;
