import {
  Box,
  Checkbox,
  CheckboxGroup,
  CheckboxGroupProps,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  StackProps,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FiSearch } from 'react-icons/fi';
import { Options } from '../types';

type CheckboxFilterProps = Omit<CheckboxGroupProps, 'onChange'> & {
  hideLabel?: boolean;
  options: Options;
  label?: string;
  onChange?: (value: string[]) => void;
  spacing?: StackProps['spacing'];
  showSearch?: boolean;
};

function CheckboxFilter(props: CheckboxFilterProps) {
  const [t] = useTranslation();
  const {
    options,
    label,
    hideLabel,
    spacing = '2',
    showSearch,
    value,
    ...rest
  } = props;

  return (
    <Stack as="fieldset" spacing={spacing}>
      {!hideLabel && (
        <FormLabel
          fontWeight="semibold"
          as="legend"
          mb="0"
          data-testid="checkbox-label"
        >
          {label}
        </FormLabel>
      )}
      {showSearch && (
        <InputGroup size="sm" pb="1" data-testid="checkbox-search">
          <Input
            placeholder={t('common.labels.search')}
            rounded="md"
            focusBorderColor={mode('brand.500', 'brand.200')}
          />
          <InputRightElement
            pointerEvents="none"
            color="gray.400"
            fontSize="lg"
          >
            <FiSearch />
          </InputRightElement>
        </InputGroup>
      )}
      <CheckboxGroup value={value} {...rest}>
        {options.map((option) => (
          <Checkbox
            key={option.value}
            value={option.value}
            colorScheme="brand"
            data-testid="checkbox-options"
          >
            <span>{option.label}</span>
            {option.count != null && (
              <Box as="span" color="gray.500" fontSize="sm" ml="1">
                {option.count}
              </Box>
            )}
          </Checkbox>
        ))}
      </CheckboxGroup>
    </Stack>
  );
}

export default CheckboxFilter;
