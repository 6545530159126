import { Popover } from '@chakra-ui/react';
import {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { useFilterState } from '../../hooks/useFilterState';
import { Options } from '../../types';
import CheckboxFilter from '../CheckboxFilter';
import FilterPopoverButton from '../FilterPopoverButton';
import FilterPopoverContent from '../FilterPopoverContent';

export type CheckboxFilterPopoverProps = {
  label: string;
  options: Options;
  onSave: (selectedValues: string[]) => void;
  onClear: () => void;
  defaultValue?: string[];
};

export const CheckboxFilterPopover = forwardRef(function CheckboxFilterPopover(
  { options, label, onSave, onClear, defaultValue }: CheckboxFilterPopoverProps,
  ref: ForwardedRef<{ clear: () => void }>
) {
  const state = useFilterState({
    defaultValue: defaultValue ?? [],
    onSubmit: onSave,
    onReset: onClear,
  });

  const [selectedCount, setSelectedCount] = useState(defaultValue?.length ?? 0);

  useEffect(() => {
    if (defaultValue) {
      state.onChange(defaultValue);
      setSelectedCount(defaultValue.length);
    }
  }, [defaultValue, state]);

  useImperativeHandle(ref, () => {
    return {
      clear: () => {
        state.onReset();
        setSelectedCount(0);
      },
    };
  }, [state]);

  return (
    <Popover placement="bottom-start">
      <FilterPopoverButton label={label} selectedCount={selectedCount} />
      <FilterPopoverContent
        isCancelDisabled={!state.canCancel}
        onClickApply={state.onSubmit}
        onClickCancel={() => {
          state.onReset();
          setSelectedCount(0);
        }}
      >
        <CheckboxFilter
          hideLabel
          value={state.value}
          onChange={(v) => {
            state.onChange(v);
            setSelectedCount(v.length);
          }}
          options={options}
        />
      </FilterPopoverContent>
    </Popover>
  );
});
