import { useEffect } from 'react';

import { HAS_REDIRECTED } from '@cccom/auth/keycloak';
import { APPS } from '@cccom/config/app-config';
import { useEnvironment } from '@cccom/config/env';
import { useSettings } from '@cccom/shared/settings';

/**
 * hook that controls auto redirection when a user:
 * 1. has access to only one app
 * 2. has accessed an app before
 */
function useAutoRedirect() {
  const env = useEnvironment();
  const { appList } = useSettings();

  useEffect(() => {
    const hasRedirected = localStorage.getItem(HAS_REDIRECTED);

    if (!hasRedirected && appList.length > 0) {
      // redirect user to app if only one app exists
      if (appList.length === 1) {
        const app = appList[0];
        localStorage.setItem(HAS_REDIRECTED, 'true');
        window.location.href = APPS.getUrl(env, app);
      }

      // redirect user to their last accessed app
      else {
        const app = appList.find((a) => a.lastAccessed);
        localStorage.setItem(HAS_REDIRECTED, 'true');
        if (app) window.location.href = APPS.getUrl(env, app);
      }
    }
  }, [appList, env]);
}

export default useAutoRedirect;
