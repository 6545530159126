import { Box, Grid, GridItem } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { useIsOnline } from '@cccom/shared/utils/common';
import { AppIds } from '@cccom/config/app-type';

import { CCErrorPage } from '../error-page/CCErrorPage';
import LimitScreenSize from '../limit-screen-size/LimitScreenSize';
import { Sidebar, SidebarProps } from '../sidebar/Sidebar';
import { Topbar, TopbarProps } from '../topbar/Topbar';
import { UIProvider } from '../ui.context';

export type SharedLayoutProps<TApp extends AppIds> = {
  children: ReactNode;
  topbar: TopbarProps;
  sidebar?: SidebarProps<TApp>;
  maxWidth?: string;
};

export function SharedLayout<TApp extends AppIds>({
  children,
  topbar,
  sidebar,
  maxWidth,
}: SharedLayoutProps<TApp>) {
  const { isOffline } = useIsOnline();

  if (isOffline)
    return (
      <LimitScreenSize>
        <CCErrorPage type="NO_INTERNET" />
      </LimitScreenSize>
    );

  return (
    <UIProvider>
      <LimitScreenSize>
        <Grid
          minHeight="100vh"
          gridTemplateColumns="min-content auto"
          gridTemplateRows="max-content 1fr"
          templateAreas={`"header header"
                  "nav main"`}
        >
          <GridItem area="header">
            <Topbar {...topbar} />
          </GridItem>

          <GridItem area="nav" colSpan={1}>
            {sidebar && <Sidebar {...sidebar} />}
          </GridItem>

          <GridItem
            area="main"
            minW="0"
            colSpan={1}
            colStart={2}
            marginTop="var(--topbar-height)"
            overflowY="auto"
            sx={{
              scrollbarGutter: 'stable both-edges',
            }}
          >
            <Box
              id="shared-content"
              padding="7"
              marginLeft="auto"
              marginRight="auto"
              maxWidth={maxWidth}
              height="calc(100vh - var(--topbar-height))"
            >
              {children}
            </Box>
          </GridItem>
        </Grid>
      </LimitScreenSize>
    </UIProvider>
  );
}
