import { theme as proTheme } from '@chakra-ui/pro-theme';
import { defineStyle, defineStyleConfig, extendTheme } from '@chakra-ui/react';

const baseTheme = extendTheme(proTheme);

const baseStyle = defineStyle({
  ...baseTheme['components'].Table.baseStyle,
  table: {
    ...baseTheme['components'].Table.baseStyle.table,
    whiteSpace: 'nowrap',

    tbody: {
      tr: {
        '.table-ellipsis, .table-ellipsis > :first-of-type': {
          maxWidth: '300px',
          overflow: 'hidden',
        },
      },
    },
  },
  th: {
    ...baseTheme['components'].Table.baseStyle.th,
    fontWeight: '600',
    textTransform: 'capitalize',
    letterSpacing: 'normal',
    borderTopWidth: '1px',
    whiteSpace: 'nowrap',
  },
});

const stripedVariant = defineStyle({
  td: {
    borderBottom: '0px',
  },
  thead: {
    backgroundColor: 'white',
    tr: {
      th: {
        color: 'blue.950',
        fontSize: '14px',
        borderWidth: '0px',
      },
    },
  },
});

const accordionVariant = defineStyle({
  ...stripedVariant,
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0 10px',
    tbody: {
      '> tr': {
        position: 'relative',

        'td:first-of-type': {
          borderLeft: '3px',
          borderColor: 'brand.400',
          borderStyle: 'solid',
        },

        '&:nth-of-type(odd)': {
          td: { bg: 'gray.100' },
        },

        '&:nth-last-of-type(-n + 1)': {
          'td:after': {
            content: '""',
            borderBottom: '1px',
            borderColor: 'chakra-border-color',
          },
        },
      },
      '.sub-component': {
        td: {
          '&:first-of-type': {
            border: 0,
          },

          '> div': {
            marginTop: '-10px',
            borderLeft: '3px',
            borderColor: 'brand.400',
            borderStyle: 'solid',
          },
        },
      },
    },
  },
});

const drawerVariant = defineStyle({
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0 8px',
    paddingRight: '1rem',

    thead: {
      th: {
        borderBottom: '1px solid',
        borderTop: '1px solid',
        borderColor: 'gray.200',
        paddingLeft: '5',
        paddingRight: '5',
      },
    },

    tbody: {
      tr: {
        '&[data-selected="true"]': {
          td: { borderColor: 'brand.500' },
        },

        td: {
          padding: '5',
          borderColor: 'gray.200',
          borderTopWidth: '1px',
          borderBottomWidth: '1px',

          '&:first-of-type': {
            borderLeftWidth: '1px',
          },

          '&:last-of-type': {
            borderRightWidth: '1px',
          },
        },
      },
    },
  },
});

const minimalDrawerVariant = defineStyle({
  table: {
    borderCollapse: 'separate',
    border: 'none',
    borderSpacing: '0 5px',

    thead: {
      display: 'none',
    },

    tbody: {
      tr: {
        backgroundColor: 'gray.50',

        '&[data-selected="true"]': {
          td: { borderColor: 'blue.500' },
        },

        td: {
          padding: '5',
          '&:first-of-type': {
            borderTopLeftRadius: 'md',
            borderBottomLeftRadius: 'md',
          },
          '&:last-of-type': {
            borderTopRightRadius: 'md',
            borderBottomRightRadius: 'md',
          },
        },
      },
    },
  },
});

const variants = {
  striped: stripedVariant,
  accordion: accordionVariant,
  drawer: drawerVariant,
  minimalDrawer: minimalDrawerVariant,
};

export const Table = defineStyleConfig({
  ...baseTheme['components'].Table,
  baseStyle,
  variants,
});

export type TableVariants = keyof typeof variants;
