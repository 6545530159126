import { createContext, useContext } from 'react';

type CCFilterContextData = {
  isOpen: boolean;
  onToggle: () => void;
  hasAppliedFilters: boolean;
};

const initialState: CCFilterContextData = {
  isOpen: false,
  onToggle: () => null,
  hasAppliedFilters: false,
};
export const CCFilterContext = createContext<CCFilterContextData>(initialState);

export const useFilterContext = () => {
  const context = useContext<CCFilterContextData>(CCFilterContext);

  return context;
};
