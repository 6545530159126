import { theme as proTheme } from '@chakra-ui/pro-theme';
import { extendTheme } from '@chakra-ui/react';

import { Badge, Button, Checkbox, Popover, Table } from './components';

const globalVariables = {
  '--topbar-height': '62px',
  '--sidebar-width': '240px',
  '--sidebar-width-collapsed': '80px',
};

const baseTheme = extendTheme(proTheme);

const theme = extendTheme({
  ...baseTheme,
  styles: {
    global: {
      '#root': {
        height: 'var(--chakra-vh)',
      },
      ':root': {
        ...globalVariables,
      },
      form: {
        width: '100%',
      },
    },
  },
  components: {
    ...baseTheme['components'],
    Button,
    Table,
    Badge,
    Popover,
    Checkbox,
  },
  colors: {
    ...baseTheme['colors'],
    blue: { ...baseTheme['colors'].blue, 950: '#1E1545' },
    brand: {
      ...baseTheme['colors'].blue,
      25: '#F3FBFF',
      topbar: baseTheme['colors'].blue['500'],
    },
  },
  fonts: {
    heading: 'Lexend',
    body: 'Lexend',
    mono: 'Roboto Mono',
  },
});

export default theme;
