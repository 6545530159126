import { useMediaQuery } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useEnvironment } from '@cccom/config/env';
import ScreenSizeWarning from './ScreenSizeWarning';

export type ScreenWidth = '1024px' | '768px' | '425px' | '375px' | '320px';

type LimitScreenSizeProps = {
  minWidth?: ScreenWidth;
  children: JSX.Element;
};

function LimitScreenSize({
  children,
  minWidth = '1024px',
}: LimitScreenSizeProps) {
  const [t] = useTranslation();
  const { APP } = useEnvironment();
  const [isLargerThanLaptop] = useMediaQuery(`(min-width: ${minWidth})`, {
    ssr: false,
  });

  return (
    <>
      {children}
      <ScreenSizeWarning
        message={t('common.errors.screen_too_small', {
          replace: [t(`${APP?.name}`)],
        })}
        logoUrl={APP?.logo}
        isOpen={!isLargerThanLaptop}
      />
    </>
  );
}

export default LimitScreenSize;
