import { initializeFaro } from '@grafana/faro-web-sdk';

import { AppProvider } from '@cccom/config/app-config';
import { getFaroConfig } from '@cccom/shared/logging';
import { homeTheme } from '@cccom/shared/themes';

import { router } from './Routes';
import { environment } from '../env';

if (import.meta.env.MODE === 'mockApi') {
  import('@cccom/home/mocks').then(({ worker }) => {
    worker.start();
  });
}

if (import.meta.env.MODE === 'production')
  initializeFaro(getFaroConfig(environment.FARO_ENDPOINT));

function App() {
  return (
    <AppProvider
      theme={homeTheme}
      environment={environment}
      routerProps={{ router }}
    />
  );
}

export default App;
