import { formatInTimeZone, fromZonedTime } from 'date-fns-tz';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { i18n } from '@cccom/shared/i18n';
import { log } from '@cccom/shared/logging';

import { useSettings } from '../use-settings';

type FormatInTimeZoneProps = Parameters<typeof formatInTimeZone>;
type DisplayDateProps = {
  date: FormatInTimeZoneProps[0];
  timeZone?: FormatInTimeZoneProps[1];
  format?: FormatInTimeZoneProps[2];
  options?: FormatInTimeZoneProps[3];
};

type UseDateProps = {
  overrideTimezone?: string;
  defaultFormat?: string;
};

function useDate(props?: UseDateProps) {
  const [t] = useTranslation();
  const { timezone: settingsTimeZone } = useSettings();

  const defaultFormat = props?.defaultFormat ?? i18n.t('common.formats.date');

  const timezone = props?.overrideTimezone ?? settingsTimeZone;

  const displayDate = useCallback(
    ({
      date,
      timeZone = timezone,
      format = defaultFormat,
      options,
    }: DisplayDateProps) => {
      try {
        return formatInTimeZone(date, timeZone, format, options);
      } catch (err: unknown) {
        log.error('Date Error: date could not be formatted', err);
        return t('common.errors.invalid_date');
      }
    },
    [timezone, defaultFormat, t]
  );

  const toLocaleDateString = useCallback((date: Date) => {
    return date.toLocaleDateString('en-GB', {
      dateStyle: 'short',
    });
  }, []);

  const getTimezoneOffsetString = useCallback(() => {
    const milliseconds = fromZonedTime(new Date(), timezone);
    const timeZoneName =
      Intl.DateTimeFormat('en', {
        timeZoneName: 'short',
        timeZone: timezone,
      })
        .formatToParts(milliseconds)
        .find((i) => i.type === 'timeZoneName')?.value ?? 'GMT+0';

    const symbol = timeZoneName.substring(0, 4);
    const hour = timeZoneName.substring(4);
    const offsetHour = hour.length > 1 ? `${hour}00` : `0${hour}00`;

    const offset =
      timeZoneName.length > 3 ? `${symbol}${offsetHour}` : `${timeZoneName}`;

    return `(${offset}) ${timezone}`;
  }, [timezone]);

  return {
    timezone,
    timezoneOffset: getTimezoneOffsetString(),
    displayDate,
    toLocaleDateString,
  };
}

export default useDate;
