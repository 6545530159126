import {
  Box,
  Button,
  Card,
  CardBody,
  HStack,
  Icon,
  Link,
  LinkProps,
  StackProps,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { IconType } from 'react-icons';
import { IoMdHelpCircle } from 'react-icons/io';
import { TbHelp } from 'react-icons/tb';

import { useSettings } from '@cccom/shared/settings';

function IconCircle({
  icon,
  color = 'blackAlpha.700',
  ...stackProps
}: {
  icon: IconType;
  color?: string;
} & StackProps) {
  return (
    <VStack
      bg="white"
      fontSize="2xl"
      rounded="full"
      color={color}
      {...stackProps}
    >
      <Icon as={icon} />
    </VStack>
  );
}

function BgCircle({ multiplier }: { multiplier: number }) {
  const circleSize = '430px';
  const size = `calc(${circleSize} - ${multiplier}px)`;

  return (
    <Box
      position="absolute"
      rounded="full"
      border="solid 1px"
      borderColor="whiteAlpha.500"
      right="-110%"
      bottom="-120%"
      h={size}
      w={size}
    />
  );
}

function HelpLink({
  url,
  children,
  ...props
}: { url: string; children: ReactNode } & LinkProps) {
  return (
    <Link
      display="flex"
      target="_blank"
      href={url}
      _hover={{ textDecoration: 'none' }}
      {...props}
    >
      {children}
    </Link>
  );
}

export type HelpCardProps = { isCollapsed: boolean; variant?: 'CARD' | 'MENU' };

function HelpCard({ isCollapsed, variant = 'MENU' }: HelpCardProps) {
  const [t] = useTranslation();
  const { helpCenterUrl } = useSettings();

  if (variant === 'MENU') {
    return (
      <HelpLink _hover={{ bg: 'gray.100' }} url={helpCenterUrl}>
        <Tooltip
          fontSize="xs"
          placement="right"
          isDisabled={!isCollapsed}
          label={t('support.help_center')}
        >
          <HStack
            py="4"
            px="7"
            gap="4"
            w="full"
            color="gray.500"
            justifyContent={isCollapsed ? 'center' : 'start'}
          >
            <IconCircle bg="transparent" color="gray.500" icon={TbHelp} />
            <Text
              opacity={isCollapsed ? '0' : '1'}
              position={isCollapsed ? 'absolute' : 'initial'}
              transition={isCollapsed ? 'none' : 'ease-in opacity 0.3s'}
            >
              {t('support.help_center')}
            </Text>
          </HStack>
        </Tooltip>
      </HelpLink>
    );
  }

  if (isCollapsed && variant === 'CARD')
    return (
      <HelpLink py="4" px="7" url={helpCenterUrl}>
        <IconCircle
          icon={TbHelp}
          color="brand.500"
          _hover={{ bg: 'gray.100' }}
        />
      </HelpLink>
    );

  return (
    <Card
      position="relative"
      width="184px"
      bg="brand.300"
      color="white"
      fontSize="sm"
      overflow="hidden"
      rounded="2xl"
      py="4"
      px="7"
    >
      <CardBody display="flex" flexDir="column" gap="4" padding="4" zIndex="1">
        <IconCircle
          w="10"
          h="10"
          p="2"
          color="brand.300"
          icon={IoMdHelpCircle}
        />

        <Box>
          <Text fontWeight="bold">{t('support.card.title')}</Text>
          <Text>{t('support.card.description')}</Text>
        </Box>

        <HelpLink url={helpCenterUrl}>
          <Button w="full" bg="white" color="gray.500" rounded="2xl" size="sm">
            {t('common.labels.documentation')}
          </Button>
        </HelpLink>
      </CardBody>

      {/* Background Circles */}
      {Array.from({ length: 6 }).map((_, i) => {
        const index = i + 1;
        const multiplier = index * 30;

        // eslint-disable-next-line react/no-array-index-key
        return <BgCircle key={i} multiplier={multiplier} />;
      })}
    </Card>
  );
}

export default HelpCard;
