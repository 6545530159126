import {
  Badge,
  Flex,
  HStack,
  Icon,
  PopoverTrigger,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import React from 'react';
import { HiChevronDown } from 'react-icons/hi';

type FilterPopoverButtonProps = {
  label: string;
  icon?: React.ElementType;
  selected?: boolean;
  selectedCount?: number;
};

function FilterPopoverButton(props: FilterPopoverButtonProps) {
  const { label, icon, selected, selectedCount = 0 } = props;

  return (
    <PopoverTrigger>
      <HStack
        justify="space-between"
        position="relative"
        as="button"
        fontSize="sm"
        borderWidth="1px"
        zIndex="1"
        rounded="lg"
        paddingStart="3"
        paddingEnd="2"
        paddingY="1.5"
        spacing="1"
        data-selected={selected || undefined}
        _expanded={{ bg: mode('brand.25', 'brand.400') }}
        _selected={{ bg: 'brand.50', borderColor: 'brand.500' }}
      >
        {icon && (
          <Icon as={icon} boxSize="2" data-testid="filter-option-icon" />
        )}
        <Flex alignItems="center" gap="2">
          <Text fontWeight="medium">{label}</Text>

          <Badge
            visibility={selectedCount > 0 ? 'visible' : 'hidden'}
            variant="solid"
            rounded="full"
            colorScheme="brand"
            size="sm"
          >
            {selectedCount}
          </Badge>
        </Flex>
        <Icon as={HiChevronDown} fontSize="xl" color="gray.500" />
      </HStack>
    </PopoverTrigger>
  );
}

export default FilterPopoverButton;
