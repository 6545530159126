import {
  Center,
  Flex,
  Image,
  Modal,
  ModalContent,
  Text,
} from '@chakra-ui/react';

type ScreenSizeWarningProps = {
  message: string;
  logoUrl: string | undefined;
  isOpen: boolean;
};

function ScreenSizeWarning({
  message,
  logoUrl,
  isOpen,
}: ScreenSizeWarningProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {}}
      isCentered
      size="full"
      motionPreset="none"
    >
      <ModalContent>
        <Center width="100vw" height="100vh">
          <Flex
            direction="column"
            gap="8"
            justifyContent="center"
            padding="8"
            maxWidth="80vw"
          >
            {logoUrl && <Image src={logoUrl} alt="app logo" height="40px" />}
            <Text textAlign="center" fontSize="18">
              {message}
            </Text>
          </Flex>
        </Center>
      </ModalContent>
    </Modal>
  );
}

export default ScreenSizeWarning;
