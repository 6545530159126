import { Faro } from '@grafana/faro-web-sdk';
import { ReactNode, createContext } from 'react';

type Log = {
  error: (message: string, ...errors: unknown[]) => void;
};

type CCLogProviderProps = { children: ReactNode };

declare const window: Window & { faro?: Faro };

export const CCLogContext = createContext<Log>({} as Log);

const faroInstance = window?.faro;

/* 
   faro expects errors in a certain format but sometimes we only 
   return a string and optionally an error object of unknown type.
   This function just converts whatever is passed in to the format 
   faro expects. 
*/
const transformErrorForFaro = (message: string, error: unknown) => {
  if (!error) return new Error(message);

  return {
    message,
    name: message.split(':')[0],
    ...error,
  };
};

export const log = {
  info: (message: string) => {
    console.log(message);
    if (faroInstance) faroInstance.api.pushLog([message]);
  },
  error: (message: string, ...errors: unknown[]) => {
    console.error(message, errors);

    errors.forEach(
      (error) =>
        faroInstance &&
        faroInstance.api.pushError(transformErrorForFaro(message, error))
    );
  },
};

export function CCLogProvider({ children }: CCLogProviderProps) {
  return <CCLogContext.Provider value={log}>{children}</CCLogContext.Provider>;
}
