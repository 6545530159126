import { createContext, useContext } from 'react';

export type CCDetailLayoutData<T> = T;

const CCDetailLayoutContext = createContext<CCDetailLayoutData<any> | null>(
  null
);

export function useCCDetailLayoutContext<T>() {
  const context = useContext<CCDetailLayoutData<T> | null>(
    CCDetailLayoutContext
  );

  if (!context) {
    throw new Error(
      'CCDetailLayout.* component must be rendered as child of CCDetailLayout component'
    );
  }

  return context;
}

export default CCDetailLayoutContext;
