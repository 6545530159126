import { CCPermissions } from './types';

/**
 * converts string array into nested object
 *
 * @param keys
 * @returns an object of {[key]: string}
 */
function createObjFromKeys(keys: string[]) {
  const mappedObj: { [x: string]: any } = {};
  let currObj = mappedObj;

  for (let index = 0; index < keys.length; index += 1) {
    const key = keys[index];
    const nextIndex = index + 1;
    const isLastKey = nextIndex === keys.length - 1;
    if (isLastKey) {
      currObj[key] = keys[nextIndex];
      break;
    }

    currObj[key] = {};
    currObj = currObj[key];
  }

  return mappedObj;
}

function isObject(item: unknown) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

/**
 * Only for use with permissionMapper. Doesn't work with circular references
 *
 * credits for mergeDeep https://stackoverflow.com/a/34749873
 *
 * @param target target object where source keys are assigned/added to
 * @param sources source object that is spread into an array to create a base-case for the function
 */
function mergeDeepToArray(
  target: { [x: string]: any },
  ...sources: [{ [x: string]: any }]
): { [x: string]: unknown } {
  // base-case, if sources is empty, return the final object
  if (!sources.length) return target;
  // move to next source object
  const source = sources.shift();

  if (isObject(target) && isObject(source) && source) {
    Object.keys(source).forEach((key) => {
      const sourceValue = source[key];
      const targetArrExists =
        typeof sourceValue === 'string' && Array.isArray(target[key]);

      if (isObject(sourceValue)) {
        // create new object with key
        if (!target[key]) Object.assign(target, { [key]: {} });
        // recurse
        mergeDeepToArray(target[key], sourceValue);
      } else if (targetArrExists) {
        // add value to array
        Object.assign(target, { [key]: [...target[key], sourceValue] });
      } else {
        // create new array with value
        Object.assign(target, { [key]: [sourceValue] });
      }
    });
  }

  return mergeDeepToArray(target, ...sources);
}

/**
 * Map array of permission strings into a nested object
 *
 * @param permissions example: ['RPC::INCOMPLETE::edit']
 * @param delimiter default delimiter "::"
 * @returns
 */
export default function mapPermissions(
  permissions: string[],
  delimiter = '::'
): CCPermissions {
  // 1. split the array of strings into an array of objects
  const permissionMapArray = permissions.map((permission) => {
    const keys = permission.split(delimiter);
    return createObjFromKeys(keys);
  });

  // 2. combine array of permission objects with deep merge
  let permissionMap: { [x: string]: any } = {};
  permissionMapArray.forEach((permissionMapArrayItem) => {
    permissionMap = mergeDeepToArray(permissionMap, permissionMapArrayItem);
  });

  return permissionMap;
}
