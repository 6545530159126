import { theme as proTheme } from '@chakra-ui/pro-theme';
import { defineStyleConfig, extendTheme } from '@chakra-ui/react';

const baseTheme = extendTheme(proTheme);

const Popover = defineStyleConfig({
  ...baseTheme['components'].Popover,
  baseStyle: {
    ...baseTheme['components'].Popover.baseStyle,
    popper: {
      ...baseTheme['components'].Popover.baseStyle.popper,
      zIndex: 'popover',
    },
  },
});

export default Popover;
