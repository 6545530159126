import { createContext, useMemo, useState } from 'react';

import { useKeycloak } from '@cccom/auth/keycloak';
import { Permissions } from '@cccom/auth/permissions-type';

import {
  PermissionProviderProps,
  PermissionsContextType,
  PermissionsContextValue,
} from './types';

export const PermissionsContext = createContext<PermissionsContextType>({
  permissionsContext: {
    roles: [],
    permissions: {},
    key: '',
  },
  setPermissionsContext: () => null,
});

export function PermissionsProvider({ children }: PermissionProviderProps) {
  const { realmRoles } = useKeycloak();
  const [permissionsContext, setPermissionsContext] =
    useState<PermissionsContextValue>({
      roles: realmRoles as Permissions[],
      permissions: {},
      key: '',
    });

  const permissionContextValue = useMemo(
    () => ({
      permissionsContext,
      setPermissionsContext,
    }),
    [permissionsContext]
  );

  return (
    <PermissionsContext.Provider value={permissionContextValue}>
      {children}
    </PermissionsContext.Provider>
  );
}
