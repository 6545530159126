import baseTheme from '../../base-theme';

const GRAY = {
  ...baseTheme['colors'].gray,
  100: baseTheme['colors'].gray['200'],
  800: baseTheme['colors'].gray['900'],
};

const EnvironmentStatusColors = {
  UNQUALIFIED: {
    ...baseTheme['colors'].red,
    800: baseTheme['colors'].red['900'],
  },
  QUALIFIED: {
    ...baseTheme['colors'].green,
    800: baseTheme['colors'].green['900'],
  },
  CHANGE_DETECTED: {
    ...baseTheme['colors'].orange,
    800: baseTheme['colors'].orange['900'],
  },
  IN_PROGRESS: {
    ...baseTheme['colors'].cyan,
    800: baseTheme['colors'].cyan['900'],
  },
};

const scanStatusColors = {
  SCANNING: {
    ...baseTheme['colors'].purple,
    800: baseTheme['colors'].purple['900'],
  },
  DONE: GRAY,
  REJECTED: GRAY,
  ERROR: EnvironmentStatusColors.UNQUALIFIED,
  APPROVED: EnvironmentStatusColors.QUALIFIED,
};

const changeStatusColors = {
  OPEN: EnvironmentStatusColors.CHANGE_DETECTED,
  UNDER_REVIEW: {
    ...baseTheme['colors'].yellow,
    800: baseTheme['colors'].yellow['900'],
  },
  REJECTED: EnvironmentStatusColors.UNQUALIFIED,
  APPROVED: EnvironmentStatusColors.QUALIFIED,
};

const reviewStatusColors = {
  PENDING: {
    ...baseTheme['colors'].gray,
    100: baseTheme['colors'].gray['200'],
    800: baseTheme['colors'].gray['900'],
  },
};

export const QIStatusColors = {
  ...EnvironmentStatusColors,
  ...scanStatusColors,
  ...changeStatusColors,
  ...reviewStatusColors,
};
