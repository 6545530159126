import { extendTheme } from '@chakra-ui/react';
import baseTheme from '../../base-theme';

const logbookStatus = {
  DRAFT: {
    ...baseTheme['colors'].red,
    800: baseTheme['colors'].red['900'],
  },
  FOR_REVIEW: {
    ...baseTheme['colors'].yellow,
    800: baseTheme['colors'].yellow['900'],
  },
  OFFICIAL: {
    ...baseTheme['colors'].green,
    800: baseTheme['colors'].green['900'],
  },
  RETIRED: {
    ...baseTheme['colors'].gray,
    100: baseTheme['colors'].gray['300'],
    800: baseTheme['colors'].gray['900'],
  },
};

const theme = extendTheme({
  ...baseTheme,
  colors: {
    ...baseTheme['colors'],
    ...logbookStatus,
    brand: {
      ...baseTheme['colors'].green,
      topbar: baseTheme['colors'].green['400'],
      25: baseTheme['colors'].green['50'],
    },
  },
});

export default theme;
