import { ReactNode, useState } from 'react';
import EnvironmentContext from './context';
import { EnvironmentType } from './types';

type EnvironmentProps = {
  children: ReactNode;
  environment: EnvironmentType;
};

function CCEnvironmentProvider({ environment, children }: EnvironmentProps) {
  const [env] = useState<EnvironmentType>(environment);

  return (
    <EnvironmentContext.Provider value={env}>
      {children}
    </EnvironmentContext.Provider>
  );
}

export default CCEnvironmentProvider;
