import { ToastId, useToast, UseToastOptions } from '@chakra-ui/react';
import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import Keycloak from 'keycloak-js';

import { ACRValue, startACRFlow, useKeycloak } from '@cccom/auth/keycloak';
import { useEnvironment } from '@cccom/config/env';
import { log } from '@cccom/shared/logging';

import { apiErrorHandler, tokenInterceptor } from './axiosInterceptors';
import CCAxiosContext from './context';

function addResponseInterceptors({
  axiosInstance,
  keycloak,
  acr,
  toast,
}: {
  axiosInstance: AxiosInstance;
  keycloak: Keycloak;
  acr: ACRValue;
  toast: (options?: UseToastOptions) => ToastId;
}) {
  axiosInstance.interceptors.response.use(
    (response) => {
      if (acr === 'esig')
        startACRFlow({ keycloak, acrValue: 'standard', prompt: 'none' });
      return response;
    },
    (error) => {
      log.error(
        `Http Error: Error occurred during api call: ${error?.config?.url} :`,
        error
      );
      if (axios.isAxiosError(error)) {
        apiErrorHandler(error, toast);
      }
      throw error;
    }
  );
}

function addAuthInterceptors({
  axiosInstance,
  keycloak,
}: {
  axiosInstance: AxiosInstance;
  keycloak: Keycloak;
}) {
  axiosInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      return tokenInterceptor(config, keycloak.token);
    }
  );
}

type CCAxiosProviderProps = {
  children: JSX.Element;
  isWrappingTest?: boolean;
};

// Todo: remove in future when all api calls use hooks
// eslint-disable-next-line import/no-mutable-exports
export let axiosInstance: AxiosInstance;

export function CCAxiosProvider({
  children,
  isWrappingTest = false,
}: CCAxiosProviderProps) {
  const { BACKEND_ENDPOINT, APP } = useEnvironment();
  const { keycloak, acr } = useKeycloak();
  const toast = useToast();

  const config = {
    baseURL: BACKEND_ENDPOINT,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-APP': APP?.id,
    },
  };

  axiosInstance = axios.create(config);

  /*
    When run by jest as part of a test these interceptors that depend on keycloak
    cause the test to fail. We skip them here if the provider is used in a test.
  */
  if (!isWrappingTest) addAuthInterceptors({ axiosInstance, keycloak });

  addResponseInterceptors({ axiosInstance, keycloak, acr, toast });

  return (
    <CCAxiosContext.Provider value={axiosInstance}>
      {children}
    </CCAxiosContext.Provider>
  );
}
