import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { log } from '@cccom/shared/logging';

type SSEContextType = {
  sse: EventSource;
  listeners: Map<string, (event: MessageEvent<any>) => void>;
  listening: boolean;
  reconnect: VoidFunction;
  close: VoidFunction;

  addListener: (
    type: string,
    listener: (event: MessageEvent<any>) => void
  ) => void;
};

export const SSEContext = createContext<SSEContextType | null>(null);

const createEventSource = (connectionURL: string) => {
  return new EventSource(connectionURL);
};

// TODO: improve/refactor this
export function SSEProvider({ children }: { children: React.ReactNode }) {
  const [listeners, setListeners] = useState<
    Map<string, (event: MessageEvent<any>) => void>
  >(new Map());
  const [listening, setListening] = useState(false);
  const [sse, setSSE] = useState<EventSource>(() =>
    createEventSource('http://localhost:8080/streaming')
  );

  const close = () => {
    setListeners(new Map());
    setListening(false);
    sse.close();
  };

  useEffect(() => {
    sse.onopen = () => {
      setListening(true);
      log.info('sse connection opened');
    };

    sse.onerror = (event) => {
      log.error('Something went wrong with the sse', event);
      close();
    };

    return () => {
      close();
    };
  }, [sse]);

  const reconnect = () => {
    if (!sse || sse.readyState === sse.CLOSED) {
      log.info('sse connection closed, reconnecting');
      setListeners(new Map());
      setSSE(createEventSource('http://localhost:8080/streaming'));
    }
  };

  const addListener = useCallback(
    (
      type: string,
      listener: (this: EventSource, event: MessageEvent<any>) => any
    ) => {
      reconnect();

      if (!listeners.get(type)) {
        setListeners(listeners.set(type, listener));
        sse.addEventListener(type, listener);

        log.info(`added event listener ${new Date()}`);
      } else {
        log.info('event listener already exists');
      }
    },
    [listeners, sse]
  );

  const value: SSEContextType = useMemo(
    () => ({ sse, listeners, listening, reconnect, addListener, close }),
    [listening, listeners, sse, addListener]
  );

  return <SSEContext.Provider value={value}>{children}</SSEContext.Provider>;
}
