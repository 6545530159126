import { extendTheme } from '@chakra-ui/react';

import baseTheme from '../../base-theme';
import { SysInventoryStatusColors } from './sys-inventory-status-colors';

const theme = extendTheme({
  ...baseTheme,
  colors: {
    ...baseTheme['colors'],
    ...SysInventoryStatusColors,
    brand: {
      ...baseTheme['colors'].purple,
      topbar: baseTheme['colors'].purple['400'],
      25: baseTheme['colors'].purple['100'],
    },
  },
});

export default theme;
