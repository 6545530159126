import { createContext } from 'react';

import { EnvironmentType } from './types';

const EnvironmentContext = createContext<EnvironmentType>({
  production: false,
  mockApi: false,
  BACKEND_ENDPOINT: '',
  KEYCLOAK_ENDPOINT: '',
  FARO_ENDPOINT: '',
  STATIC_ASSETS_URL: '',
});

export default EnvironmentContext;
