import {
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { IconLogout } from '@tabler/icons-react';
import { Fragment, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useKeycloak } from '@cccom/auth/keycloak';
import { version } from '@cccom/package';
import { useDate } from '@cccom/shared/settings';

import { CCAvatar } from '../avatar/CCAvatar';

type ProfileMenuProps = {
  isCollapsed?: boolean;
  children?: ReactNode;
  menuItems?: ReactNode[];
};

export function ProfileMenu({
  isCollapsed,
  children,
  menuItems,
}: ProfileMenuProps) {
  const [t] = useTranslation();
  const { keycloak, email, name } = useKeycloak();
  const { timezoneOffset } = useDate();

  const avatarWidth = 'var(--chakra-space-8)';
  const spacing = 'calc(var(--chakra-space-14) + var(--chakra-space-4))';
  const maxWidth = `calc(var(--sidebar-width) - ${spacing} - ${avatarWidth})`;

  const menuPadding = { px: '6', py: '2' };

  return (
    <Menu placement="right-end">
      <Tooltip
        fontSize="xs"
        placement="right"
        isDisabled={!isCollapsed}
        label={t('profile.user_profile')}
      >
        <MenuButton
          data-cy="profileMenu"
          py="4"
          px="6"
          _hover={{ bg: 'gray.100' }}
        >
          <HStack spacing="4">
            <CCAvatar name={name} />
            <Flex
              direction="column"
              alignItems="start"
              opacity={isCollapsed ? '0' : '1'}
              position={isCollapsed ? 'absolute' : 'initial'}
              transition={isCollapsed ? 'none' : 'ease-in opacity 0.3s'}
            >
              <Text
                fontSize="sm"
                textAlign="left"
                maxWidth={maxWidth}
                noOfLines={1}
              >
                {name}
              </Text>
              <Text
                fontSize="xs"
                textAlign="left"
                color="gray.500"
                maxWidth={maxWidth}
                noOfLines={1}
              >
                {email}
              </Text>
            </Flex>
          </HStack>
        </MenuButton>
      </Tooltip>

      <Portal>
        <MenuList zIndex="3">
          <VStack alignItems="start" {...menuPadding}>
            <Text fontSize="medium">{name}</Text>
            <Text color="gray.500" fontSize="xs">
              {email}
            </Text>
          </VStack>

          <MenuDivider />

          {children && (
            <>
              <VStack alignItems="start" {...menuPadding}>
                {children}
              </VStack>
              <MenuDivider />
            </>
          )}

          <VStack
            alignItems="start"
            color="gray.500"
            fontSize="xs"
            {...menuPadding}
          >
            <Text>{timezoneOffset}</Text>
            <Text>
              {t('common.labels.version')}: {version}
            </Text>
          </VStack>

          <MenuDivider />

          {menuItems?.map((item, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={index}>
                {item}
                <MenuDivider />
              </Fragment>
            );
          })}

          <MenuItem
            gap="2"
            color="blue.950"
            onClick={() => keycloak.logout()}
            {...menuPadding}
          >
            <Icon as={IconLogout} color="blue.950" boxSize="5" />
            {t('common.actions.logout')}
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  );
}
