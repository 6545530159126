import { ChakraProvider } from '@chakra-ui/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { StrictMode } from 'react';
import { RouterProvider, RouterProviderProps } from 'react-router-dom';

import { CCKeycloakProvider } from '@cccom/auth/keycloak';
import { CCEnvironmentProvider, EnvironmentType } from '@cccom/config/env';
import { CCAxiosProvider, queryClient } from '@cccom/shared/data-access';
import '@cccom/shared/i18n';
import { CCLogProvider } from '@cccom/shared/logging';

type AppProviderProps = {
  theme: Record<string, unknown>;
  environment: EnvironmentType;
  routerProps: RouterProviderProps;
};

function AppProvider({ theme, environment, routerProps }: AppProviderProps) {
  return (
    <ChakraProvider theme={theme}>
      <CCEnvironmentProvider environment={environment}>
        <CCKeycloakProvider>
          <StrictMode>
            <CCAxiosProvider>
              <CCLogProvider>
                <QueryClientProvider client={queryClient}>
                  <RouterProvider {...routerProps} />
                  {!environment.production && (
                    <ReactQueryDevtools initialIsOpen={false} />
                  )}
                </QueryClientProvider>
              </CCLogProvider>
            </CCAxiosProvider>
          </StrictMode>
        </CCKeycloakProvider>
      </CCEnvironmentProvider>
    </ChakraProvider>
  );
}

export default AppProvider;
