import {
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  usePopoverContext,
} from '@chakra-ui/react';
import {
  FilterActionButtons,
  FilterActionButtonsProps,
} from './FilterActionButtons';

type FilterPopoverContentProps = FilterActionButtonsProps & {
  header?: string;
  children?: React.ReactNode;
};

function FilterPopoverContent(props: FilterPopoverContentProps) {
  const { header, children, onClickCancel, onClickApply, isCancelDisabled } =
    props;
  const { onClose } = usePopoverContext();
  return (
    <PopoverContent
      _focus={{ shadow: 'none', outline: 0 }}
      _focusVisible={{ shadow: 'outline' }}
    >
      {header && <PopoverHeader srOnly>{header}</PopoverHeader>}
      <PopoverBody padding="6">{children}</PopoverBody>
      <PopoverFooter>
        <FilterActionButtons
          onClickCancel={() => {
            onClickCancel?.();
            onClose();
          }}
          isCancelDisabled={isCancelDisabled}
          onClickApply={() => {
            onClickApply?.();
            onClose();
          }}
        />
      </PopoverFooter>
    </PopoverContent>
  );
}

export default FilterPopoverContent;
