import { Button, HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export type FilterActionButtonsProps = {
  onClickCancel?: VoidFunction;
  isCancelDisabled?: boolean;
  onClickApply?: VoidFunction;
};

export function FilterActionButtons(props: FilterActionButtonsProps) {
  const { onClickApply, onClickCancel, isCancelDisabled } = props;
  const [t] = useTranslation();
  return (
    <HStack spacing="2" justify="space-between">
      <Button
        size="sm"
        variant="ghost"
        onClick={onClickCancel}
        isDisabled={isCancelDisabled}
      >
        {t('common.actions.clear')}
      </Button>
      <Button size="sm" colorScheme="brand" onClick={onClickApply}>
        {t('common.actions.show_results')}
      </Button>
    </HStack>
  );
}
